import { apiV1 } from './config';

const EXAM_RESPONSE = '/exam/response/user';

const getExamResponse = async (id) => {
  const response = await apiV1().get(`${EXAM_RESPONSE}/${id}`);
  return response;
};
const getAllExamResponses = async () => {
  const response = await apiV1().get(`${EXAM_RESPONSE}`);
  return response;
};
const getFilteredExamResponses = async (orderBy = '-id') => {
  const response = await apiV1().get(`${EXAM_RESPONSE}`);
  return response;
};
const createExamResponse = async (examResponse) => {
  const response = await apiV1().post(`${EXAM_RESPONSE}/create`, examResponse);
  return response;
};
const updateExamResponse = async (id, examResponse) => {
  const response = await apiV1().put(`${EXAM_RESPONSE}/${id}`, examResponse);
  return response;
};
const deleteExamResponse = async (id) => {
  const response = await apiV1().delete(`${EXAM_RESPONSE}/${id}/delete`);
  return response;
};

export {
  getExamResponse,
  getAllExamResponses,
  getFilteredExamResponses,
  createExamResponse,
  updateExamResponse,
  deleteExamResponse,
};
