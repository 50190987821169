import { createSlice } from '@reduxjs/toolkit';
import { postAuthV1, postUnAuthV1 } from '../../services/http.service';
import initialState from './initialState';

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    reset: (state) => initialState,

    updateUser(state, { payload }) {
      state.userName = payload?.userName || initialState.userName;
      state.fullName = payload?.fullName || initialState.fullName;
      state.userPermissions = payload?.userPermissions || initialState.userName;
      state.userPermissionGroups =
        payload?.userPermissionGroups || initialState.userPermissionGroups;
      state.email = payload?.email || initialState.email;
      state.isAuthenticated = payload?.isAuthenticated || false;
      state.jwtToken = payload?.jwtToken || null;
    },
  },
});

export const { updateUser, reset } = userSlice.actions;

export default userSlice.reducer;

export const resetSlice = () => async (dispatch) => {
  // debugger
  localStorage.removeItem('authToken');
  dispatch(reset());
};

export const getUserClaims = (user) => async (dispatch, getState) => {
  try {
    // eslint-disable-next-line max-len
    // get user details
    // const users = [
    //   { email: 'admin1@mail.com', password: 'password' },
    //   { email: 'admin2@mail.com', password: 'password' },
    //   { email: 'admin3@mail.com', password: 'password' },
    //   { email: 'admin4@mail.com', password: 'password' },
    //   { email: 'admin5@mail.com', password: 'password' },
    // ];

    // let user = users[Math.floor(Math.random() * 5)];

    // user = { email: 'admin@mail.com', password: 'admin' }; // Super user
    // const res = await postUnAuthV1('/token', user);
    // if ([200, 201].includes(res?.status)) {
    //   localStorage.setItem('authToken', res?.data?.AccessToken);
    // const user = res?.data;
    // debugger
    if (user?.AccessToken) {
      localStorage.setItem('authToken', user.AccessToken);
      const userState = {
        userName: user?.UserName || '',
        fullName: user?.FullName || '',
        userPermissions: user?.Permissions || [],
        userPermissionGroups: user?.Groups || [],
        email: user?.Email,
        isAuthenticated: true,
        AccessToken: user?.AccessToken,
        RefreshToken: user?.RefreshToken,
      };
      dispatch(updateUser(userState));
    }
    // }
  } catch (err) {}
};
