import axios from 'axios';
import { toast } from 'react-toastify';
import { objectToQueryString } from './url';
import { removeStoredAuthToken } from './authToken';
import { apiDefaults } from '../constants/constants';
import { ROUTES } from '../helpers/RouteHelper';

const api = (method, url, variables) =>
  new Promise((resolve, reject) => {
    axios({
      url: `${apiDefaults.baseURL}${url}`,
      method,
      headers: apiDefaults.headers(),
      params: method === 'get' ? variables : undefined,
      data: method !== 'get' ? variables : undefined,
      paramsSerializer: objectToQueryString,
    }).then(
      (response) => {
        resolve(response.data);
      },
      (error) => {
        if (error.response) {
          if (error?.response?.status === 401) {
            reject({
              isUnAuthUser: true,
            });
            // uncomment to logout User
            // removeStoredAuthToken();
            // history.push(ROUTES.AUTH);
          } else if (error?.response?.status === 400) {
            reject({
              apiError: error?.response?.data,
            });
          } else {
            reject(error.response.data.error);
          }
        } else {
          reject(apiDefaults.error);
        }
      },
    );
  });

const optimisticUpdate = async (url, { updatedFields, currentFields, setLocalData }) => {
  try {
    setLocalData(updatedFields);
    await api('put', url, updatedFields);
  } catch (error) {
    setLocalData(currentFields);
    const option = {
      position: 'top-right',
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    };
    toast.error(error, option);
  }
};

export default {
  get: (...args) => api('get', ...args),
  post: (...args) => api('post', ...args),
  put: (...args) => api('put', ...args),
  patch: (...args) => api('patch', ...args),
  delete: (...args) => api('delete', ...args),
  optimisticUpdate,
};
