import React, { Suspense, useEffect, useState, useRef } from 'react';
import { connect } from 'react-redux';
import { IonReactRouter } from '@ionic/react-router';
import { Capacitor } from '@capacitor/core';
import { IntlProvider } from 'react-intl';
import { Redirect, BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonSplitPane,
  IonTabBar,
  IonTabButton,
  IonTabs,
  setupIonicReact
} from '@ionic/react';
import { ellipse, square, triangle } from 'ionicons/icons';
import { initializeApp } from 'firebase/app';
import history from './utils/browserHistory';
import { NotFountPage, Loading, Home } from './modules/app/pages';
import { LoaderOneComponent } from './modules/app/components';
import { ExperimentHomePage } from './modules/experiment/pages';
import { initConfig } from './reducers/appSlice/appSlice';
/**
 * STYLING
 */

import './App.scss';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.scss';

/** Toast Styling */
import 'react-toastify/dist/ReactToastify.css';
import { AuthPage, LogoutPage } from './modules/auth/pages';
import { getMasters } from './reducers/masterSlice/masterSlice';
import SidebarWrapper from './modules/app/components/Sidebar/SidebarWrapper';
import { ROUTES } from './helpers/RouteHelper';
import SideMenuMobile from './modules/app/components/Sidebar/Mobile/SideMenuMobile';
import { InterviewHomePageNative, InterviewQuestionPageNative } from './modules/interview/pages';
import { ExamHomePageNative, ExamPageNative, ExamResultNative, ExamTxnNative } from './modules/exam/pages';
import { firebaseConfig } from './config/clientConfig';

const App = ({
  localization,
  darkMode,
  initConfigAction,
  getMastersAction,
  isAuthenticated,
  user,
  tabsEnabled,
  menuEnabled,
}) => {
  const routerRef = useRef(null);
  const [showTabs, setShowTabs] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [sideBarActive, setSideBarActive] = useState(true);

  setupIonicReact({
    mode: 'md'
  });

  useEffect(() => {
    setShowTabs(tabsEnabled);
  }, [tabsEnabled]);

  useEffect(() => {
    // Initialize Firebase
    if (!Capacitor.isNativePlatform()) {
      initializeApp(firebaseConfig);
    }
  });

  useEffect(() => {
    // (async () => {
    //   if (isAuthenticated) {
    //     await getMastersAction();
    //   }
    //   setIsLoading(false);
    // })();
  }, [isAuthenticated]);

  // eslint-disable-next-line react/prop-types
  function PrivateRoute({ component: Component, authed, ...rest }) {
    return (
      <Route
        {...rest}
        render={(props) =>
          isAuthenticated ? (
            <Component {...props} />
          ) : (
            <Redirect to={{ pathname: '/auth', state: { from: props.match?.path } }} />
          )
        }
      />
    );
  }

  if (isLoading) {
    return (
      <IntlProvider {...localization}>
        <LoaderOneComponent show />
      </IntlProvider>
    );
  }

  const Routes = () => (
    <IonRouterOutlet id="main" ref={routerRef}>
      <Route router={routerRef.current} path={ROUTES.HOME} component={Home} exact />
      <Route router={routerRef.current} path={ROUTES.AUTH} component={AuthPage} exact />
      <Route router={routerRef.current} path={ROUTES.LOGOUT} component={LogoutPage} exact />
      <Route router={routerRef.current} path={ROUTES.EXPERIMENT} component={ExperimentHomePage} />

      <Route
        router={routerRef.current}
        exact
        path={ROUTES.INTERVIEW_HOME}
        component={InterviewHomePageNative}
      />
      <Route
        router={routerRef.current}
        exact
        path={ROUTES.INTERVIEW_QUESTION}
        component={InterviewQuestionPageNative}
      />

      <Route router={routerRef.current} exact path={ROUTES.EXAM} component={ExamPageNative} />
      <Route router={routerRef.current} exact path={ROUTES.EXAM_RESULT} component={ExamResultNative} />
      <Route
        router={routerRef.current}
        exact
        path={ROUTES.EXAM_HOME}
        component={ExamHomePageNative}
      />

      <Route
        router={routerRef.current}
        exact
        path="/"
        render={() => <Redirect to={ROUTES.HOME} />}
      />
      <Route router={routerRef.current} path={ROUTES.NOT_FOUND} component={NotFountPage} />
      <Route router={routerRef.current} render={() => <Redirect to={ROUTES.NOT_FOUND} />} />
    </IonRouterOutlet>
  );

  const RoutesWithTab = () => (
    <IonTabs>
      <IonRouterOutlet id="main" ref={routerRef}>
        <Route router={routerRef.current} exact path={ROUTES.HOME} component={Home} />
        <Route router={routerRef.current} exact path={ROUTES.AUTH} component={AuthPage} />
        <Route router={routerRef.current} exact path={ROUTES.LOGOUT} component={LogoutPage} />

        <Route
          router={routerRef.current}
          exact
          path={ROUTES.INTERVIEW_HOME}
          component={InterviewHomePageNative}
        />
        <Route
          router={routerRef.current}
          exact
          path={ROUTES.INTERVIEW_QUESTION}
          component={InterviewQuestionPageNative}
        />

        <Route router={routerRef.current} exact path={ROUTES.EXAM} component={ExamTxnNative} />
        <Route router={routerRef.current} exact path={ROUTES.EXAM_RESULT} component={ExamResultNative} />
        <Route
          router={routerRef.current}
          exact
          path={ROUTES.EXAM_HOME}
          component={ExamHomePageNative}
        />

        <Route router={routerRef.current} path={ROUTES.NOT_FOUND} component={NotFountPage} />
        <Route
          router={routerRef.current}
          exact
          path="/"
          render={() => <Redirect to={ROUTES.HOME} />}
        />
        <Route router={routerRef.current} render={() => <Redirect to={ROUTES.NOT_FOUND} />} />
      </IonRouterOutlet>
      <IonTabBar mode="ios" slot="bottom" translucent>
        <IonTabButton tab="tab1" href={ROUTES.EXAM_HOME}>
          <IonIcon icon={square} />
          <IonLabel>Exam</IonLabel>
        </IonTabButton>
        <IonTabButton tab="interview" href={ROUTES.INTERVIEW_HOME}>
          <IonIcon icon={triangle} />
          <IonLabel>Interview</IonLabel>
        </IonTabButton>
        {/* <IonTabButton tab="tab2" href={ROUTES.AUTH}>
          <IonIcon icon={ellipse} />
          <IonLabel>Auth</IonLabel>
        </IonTabButton> */}
        {/* <IonTabButton tab="experiment" href={ROUTES.EXPERIMENT}>
          <IonLabel>Experiment Tab</IonLabel>
        </IonTabButton> */}
      </IonTabBar>
    </IonTabs>
  );

  return (
    <>
      <ToastContainer
        position="top-right"
        // autoClose={5000}
        // hideProgressBar={false}
        // newestOnTop={false}
        // closeOnClick
        // rtl={false}
        // pauseOnFocusLoss
        // draggable
        // pauseOnHover
      />

      {true ? (
        <IntlProvider {...localization}>
          <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
            <IonReactRouter history={history}>
              <Suspense fallback={<Loading />}>
                <IonSplitPane contentId="main" disabled={!false}>
                  <SideMenuMobile menuEnabled={false} history={history} />
                  {/*
                We use IonRoute here to keep the tabs state intact,
                which makes transitions between tabs and non tab pages smooth
                */}
                  {showTabs ? <RoutesWithTab /> : <Routes />}
                </IonSplitPane>
              </Suspense>
            </IonReactRouter>
            {/* </Suspense> */}
          </IonApp>
        </IntlProvider>
      ) : (
        <IntlProvider {...localization}>
          <IonApp className={`${darkMode ? 'dark-theme' : ''}`}>
            {/* {!isLoaded && <LoaderOneComponent />} */}
            <div className={`${sideBarActive ? 'toggled' : ''} page-wrapper chiller-theme`}>
              <Router history={history}>
                <SidebarWrapper enableSidebar setSideBarActive={setSideBarActive} />
                <main className="page-content">
                  <div className="container">
                    <Suspense fallback={<Loading />}>
                      <Switch id="main">
                        <Route path={ROUTES.HOME} component={Home} exact />
                        <Route path={ROUTES.AUTH} component={AuthPage} exact />
                        <Route path={ROUTES.LOGOUT} component={LogoutPage} exact />
                        <Route path={ROUTES.EXPERIMENT} component={ExperimentHomePage} />

                        <Route
                          exact
                          path={ROUTES.INTERVIEW_HOME}
                          component={InterviewHomePageNative}
                        />
                        <Route
                          exact
                          path={ROUTES.INTERVIEW_QUESTION}
                          component={InterviewQuestionPageNative}
                        />

                        <Route exact path={ROUTES.EXAM} component={ExamPageNative} />
                        <Route exact path={ROUTES.EXAM_HOME} component={ExamHomePageNative} />

                        <Route exact path="/" render={() => <Redirect to={ROUTES.HOME} />} />
                        <Route path={ROUTES.NOT_FOUND} component={NotFountPage} />
                        <Route render={() => <Redirect to={ROUTES.NOT_FOUND} />} />
                      </Switch>
                    </Suspense>
                  </div>
                </main>
              </Router>
            </div>
          </IonApp>
        </IntlProvider>
      )}
    </>
  );
};
const mapStateToProps = ({ app, user }) => ({
  localization: app.localization,
  darkMode: app.darkMode,
  isAuthenticated: user?.isAuthenticated,
  user,
  tabsEnabled: app?.tabsEnabled,
  menuEnabled: app?.menuEnabled,
});

const mapDispatchToProps = {
  initConfigAction: initConfig,
  getMastersAction: getMasters,
};

export default connect(mapStateToProps, mapDispatchToProps)(App);
