import {
  calendarOutline,
  hammer,
  moonOutline,
  help,
  informationCircleOutline,
  logIn,
  logOut,
  mapOutline,
  peopleOutline,
  person,
  personAdd,
} from 'ionicons/icons';
import { DEFAULT_PAGE_TITLE, PREFIX_PAGE_TITLE } from '../config/config';

export const ROUTES = {
  AUTH: '/auth',
  SIGNIN: '/auth',
  SIGNUP: '/auth',
  LOGOUT: '/logout',
  HOME: '/home',
  NOT_FOUND: '/NotFound',
  EXPERIMENT: '/experiment',

  INTERVIEW: '/interview',
  INTERVIEW_HOME: '/interview/home',
  INTERVIEW_QUESTION: '/interview/question/:id',

  EXAM_HOME: '/exam/home',
  EXAM: '/user/exam/:id',
  EXAM_RESULT: '/user/exam-result/:id',
};

export const ROUTE_INFO = [
  { Path: ROUTES.SIGNIN, title: 'Account', icon: null },
  { Path: ROUTES.SIGNUP, title: 'Account' },
  { Path: ROUTES.HOME, title: 'Home' },
  { Path: ROUTES.EXPERIMENT, title: 'Experiment' },
];

export const GetRouteInfo = (path) => {
  path = path === '/' ? ROUTES.HOME : path;
  const data = {
    path,
    title: DEFAULT_PAGE_TITLE,
    icon: null,
  };
  const result = ROUTE_INFO.find((r) => r.Path === path);
  if (result) {
    data.title = result.title ? PREFIX_PAGE_TITLE + result.title : DEFAULT_PAGE_TITLE;
    data.icon = result.icon;
  }
  return data;
};

export const DESKTOP_HEADER_ROUTES = {
  appPages: [],
  loggedInPages: [
    { title: 'Home', path: ROUTES.HOME, icon: person },
    { title: 'Experiment', path: ROUTES.EXPERIMENT, icon: help },
    // { title: "Support", path: ROUTES.HOME, icon: help },
    { title: 'Logout', path: ROUTES.LOGOUT, icon: logOut },
  ],
  loggedOutPages: [
    { title: 'Experiment', path: ROUTES.EXPERIMENT, icon: help },
    { title: 'Login', path: ROUTES.SIGNIN, icon: logIn },
    // { title: "Signup", path: ROUTES.HOME, icon: personAdd },
  ],
};

export const SIDEMENU_ROUTES = {
  appPages: [],
  loggedInPages: [
    { title: 'Experiment', path: ROUTES.EXPERIMENT, icon: help },
    { title: 'Support', path: ROUTES.HOME, icon: help },
    { title: 'Logout', path: ROUTES.HOME, icon: logOut },
  ],
  loggedOutPages: [
    { title: 'Experiment', path: ROUTES.EXPERIMENT, icon: help },
    { title: 'Login', path: ROUTES.SIGNIN, icon: logIn },
    { title: 'Signup', path: ROUTES.SIGNUP, icon: personAdd },
  ],
};
