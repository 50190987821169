import { createSlice } from '@reduxjs/toolkit';
import {
  GetMasterListBasedOnCode,
  GetMasterListBasedOnId,
  GetModuleMasterList,
} from '../../helpers/masterHelper';
import { masterCodes } from '../../helpers/typeCodes';
import { getAuthV1 } from '../../services/http.service';
import { deleteSlice, getSlice, postAndPutSlice } from '../commonSliceMethods';
import initialState from './initialState';

const masterSlice = createSlice({
  name: 'master',
  initialState,
  reducers: {
    initMasters(state, { payload }) {
      return { ...state, ...payload };
    },
    updateData(state, { payload }) {
      state[payload.key] = payload.data;
    },
  },
});

export const { initMasters, updateData } = masterSlice.actions;

export default masterSlice.reducer;

export const getMasters = () => async (dispatch, getState) => {
  let masterList = [];
  let masterDataList = [];
  let permissionList = [];
  let permissionGroupList = [];
  try {
    const resMeta = await getAuthV1('/master/meta');
    const resPermission = await getAuthV1('/account/permission');
    const resPermissionGroup = await getAuthV1('/account/group');
    const resMetaWithChild = await getAuthV1('/master/meta/details');
    if ([200].includes(resMeta?.status) && [200].includes(resMetaWithChild?.status)) {
      masterList = { key: 'masterList', data: resMeta?.data };
      dispatch(updateData(masterList));
      masterDataList = { key: 'masterDataList', data: resMetaWithChild?.data };
      dispatch(updateData(masterDataList));
    }
    if ([200].includes(resPermission?.status) && [200].includes(resPermissionGroup?.status)) {
      permissionList = resPermission?.data;
      permissionGroupList = resPermissionGroup?.data;
    }
    const masters = {
      editorTypeList: GetMasterListBasedOnCode(masterDataList?.data, masterCodes.EDITOR_TYPE),
      difficultyLevelList: GetMasterListBasedOnId(masterDataList?.data, masterCodes.DIFFICULTY),
      // domainList: [],
      questionStatusList: GetMasterListBasedOnId(
        masterDataList?.data,
        masterCodes.QUESTION_MCQ_STATUS,
      ),
      questionTypeList: GetMasterListBasedOnId(masterDataList?.data, masterCodes.QUESTION_MCQ_TYPE),
      examTypeList: GetMasterListBasedOnId(masterDataList?.data, masterCodes.EXAM_TYPE),
      markingLevelList: [],
      examStatusList: [],
      userTypeList: [],
      modulesList: GetModuleMasterList(masterDataList?.data, masterCodes.MODULE),
      tagsList: GetMasterListBasedOnId(masterDataList?.data, masterCodes.TAGS),
      permissionList,
      permissionGroupList,
    };
    dispatch(initMasters(masters));
  } catch (err) {}
};

export const getData = (url, stateKey, loaderId) => async (dispatch, getState) => {
  getSlice(dispatch, getState, updateData, url, stateKey, loaderId);
};

export const postData =
  (url, data, stateKey, loaderId, messages = null, isPut = false) =>
  async (dispatch, getState) => {
    postAndPutSlice(dispatch, getState, updateData, url, data, stateKey, loaderId, messages, isPut);
  };

export const deleteData = (url, stateKey, loaderId) => async (dispatch, getState) => {
  deleteSlice(dispatch, getState, updateData, url, stateKey, loaderId);
};
