import React from 'react';
import {
  IonHeader,
  IonMenuButton,
  IonTitle,
  IonToolbar,
  IonButtons,
  IonItem,
  IonIcon,
  IonLabel,
  IonButton,
  IonBackButton,
} from '@ionic/react';
import './HeaderMobile.scss';
import { useHistory, useLocation } from 'react-router';
import { FormattedMessage } from 'react-intl';
import { Link } from 'react-router-dom';
import { DESKTOP_HEADER_ROUTES, ROUTES, ROUTE_INFO } from '../../../../../helpers/RouteHelper';
import { connect } from 'react-redux';

/* Always add Header from the Page */
const HeaderMobile = ({ isAuthenticated, hideAppMenu, routeList, showBackButton }) => {
  // const location = useLocation();
  // const history = useHistory();

  function renderListItems(list) {
    return list
      .filter((route) => !!route.path)
      .map((p, i) => (
        <Link to={p.path} key={i}>
          <IonItem
            button
            lines="none"
            detail={false}
            // routerLink={p.path}
            // routerDirection="none"
            // className={location.pathname.startsWith(p.path) ? 'selected' : undefined}
          >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </Link>
      ));
  }

  return (
    <>
      <IonHeader>
        <IonToolbar>
          <IonButtons slot="start">
            <IonMenuButton autoHide />
          </IonButtons>
          <IonTitle
            onClick={() => {
              // history.push(ROUTES.HOME);
            }}
          >
            <FormattedMessage id="app.desktop.title" />
          </IonTitle>
          <IonButtons slot="end">{showBackButton && <IonBackButton defaultHref="/" />}</IonButtons>
        </IonToolbar>
      </IonHeader>
    </>
  );
};

const mapStateToProps = ({ app, user }) => ({
  isAuthenticated: user?.isAuthenticated,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderMobile);
