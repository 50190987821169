import React, { useEffect, useState } from 'react';
import {
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonLoading,
  IonList,
  IonItem,
  IonIcon,
  IonChip,
  IonText,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import { ExamListComponent, ExamResultComponent, SeriesListComponent } from '../../../components';
import { PageWrapperComponent } from '../../../../app/components';
import useApi from '../../../../../hooks/api';
import './ExamResult.scss';
import { useParams } from 'react-router';
import { getExamResponse } from '../../../../../services/exam.response.service';
import { timeOutline, listCircleOutline, checkmarkDoneCircleOutline } from 'ionicons/icons';
import { formatDateTime } from '../../../../../utils/dateTime';

const ExamResult = ({ router }) => {
  const params = useParams();
  const [examId, setExamId] = useState(() => params?.id);
  const [response, setResponse] = useState(null);
  const [responseDetails, setResponseDetails] = useState(null);
  const [{ data: examResponse, isLoading: isExamLoading, error: examError }, fetchExamList] =
    useApi.get(`/exam/user/details/result/${examId}`, {}, { lazy: true });

  const [
    { data: userExamResponse, isLoading: isUserExamResponseLoading, error: userExamResponseError },
    fetchUserExamResponseList,
  ] = useApi.get(`/exam/response/user?ordering=-ModifiedAt&ExamId=${examId}`, {}, { lazy: true });

  useEffect(() => {
    const fetchData = async () => {
      setResponseDetails(null);
      if (response && response?.id) {
        try {
          const result = await getExamResponse(response?.id);
          setResponseDetails(result?.data);
        } catch (e) {}
      }
    };
    fetchData();
  }, [response]);

  useEffect(() => {
    if (userExamResponse) {
      setResponse(userExamResponse?.results?.[0] || null);
    }
  }, [userExamResponse]);

  useEffect(() => {
    const id = params?.id;
    if (id !== examId) {
      setTimeout(() => {
        setExamId(id);
      }, 200);
    }
  }, [params?.id]);

  useEffect(() => {
    if (examId) {
      fetchExamList();
      fetchUserExamResponseList();
    }
  }, [examId]);

  return (
    <>
      {/* {(isExamLoading|| isSeriesLoading) && <IonLoading spinner='bubbles' animated />} */}
      <PageWrapperComponent id="question" routeList={null}>
        <IonLoading
          spinner="bubbles"
          animated
          isOpen={
            (isExamLoading || isUserExamResponseLoading) && !(examError || userExamResponseError)
          }
          message="wait a moment"
        />
        <IonItem>
          <IonLabel>Attempted At:</IonLabel>
          <IonSelect
            value={response}
            okText="Show Result"
            cancelText="Dismiss"
            onIonChange={(e) => setResponse(e.detail.value)}
          >
            {/* <IonList> */}
            {userExamResponse?.results?.map((res, i) => (
              <IonSelectOption value={userExamResponse?.results?.[i]}>
                {formatDateTime(res?.CreatedAt)}
              </IonSelectOption>
            ))}
          </IonSelect>
        </IonItem>
        {examResponse && responseDetails && (
          <ExamResultComponent exam={examResponse} examResponse={responseDetails} />
        )}
      </PageWrapperComponent>
    </>
  );
};

export default ExamResult;
