import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import { PropTypes } from 'prop-types';
import {
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonIcon,
  IonButton,
  IonLoading,
  useIonViewDidEnter,
} from '@ionic/react';
import { connect } from 'react-redux';
import { getData } from '../../../../../reducers/examSlice/examSlice';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css'; // THEME
import './Exam.scss';
import { close } from 'ionicons/icons';
import { isModuleLoading, LoaderEnum } from '../../../../../helpers/loaderHelper';
import { PageWrapperComponent } from '../../../../app/components';
import { BasicExamTemplateComponent } from '../../../components';
import { saveMenuEnabled, saveTabsEnabled } from '../../../../../reducers/appSlice/appSlice';
import { ROUTES } from '../../../../../helpers/RouteHelper';

const propTypes = {
  getDataAction: PropTypes.func.isRequired,
  examData: PropTypes.object,
  saveTabsEnabledAction: PropTypes.func,
  saveMenuEnabledAction: PropTypes.func,
};

const defaultProps = {};

const Exam = ({
  getDataAction,
  examData,
  loaderList,
  saveTabsEnabledAction,
  saveMenuEnabledAction,
}) => {
  const params = useParams();
  const history = useHistory();
  const [exam, setExam] = useState(examData);
  const [examId, setExamId] = useState(() => params?.id);
  const [isLoading, setIsLoading] = useState(false);
  const [examFinished, setExamFinished] = useState(false);

  useEffect(() => {
    if (examId && !isLoading) {
      getDataAction(`/exam/user/details/${examId}`, 'examData', LoaderEnum.exam_get);
    }
  }, []);

  useEffect(() => {
    const x = isModuleLoading(loaderList, [LoaderEnum.exam_get]);
    setIsLoading(x);
  }, [loaderList]);

  // useEffect(() => {
  //   const id = params?.id;
  //   if (id !== examId) {
  //     // setTimeout(() => {
  //       setExamId(id);
  //     // }, 200);
  //   }
  // }, [params?.id]);

  useEffect(() => {
    if (examData) {
      setExam(examData);
    }
  }, [examData]);

  useEffect(() => {
    if (examFinished) {
      history.replace(ROUTES.EXAM_HOME);
    }
  }, [examFinished]);

  // useIonViewWillEnter(() => {
  //   saveTabsEnabledAction(false);
  //   saveMenuEnabledAction(false);
  // });

  useIonViewDidEnter(() => {
    // setTimeout(() => {
    
    if (examData) {
      setExam(examData);
    }
    // },300);
  });

  useIonViewWillLeave(() => {
    saveTabsEnabledAction(true);
    saveMenuEnabledAction(true);
    setExamFinished(false);
    setExam(null);
    setExamId(null);
    setIsLoading(false);
  });

  return (
    <>
      <PageWrapperComponent id="exam" hideHeader>
        {exam && examId && !examFinished && (
          <BasicExamTemplateComponent
            exam={exam}
            examId={examId}
            examFinished={examFinished}
            setExamFinished={setExamFinished}
          />
        )}
      </PageWrapperComponent>
    </>
  );
};

Exam.propTypes = propTypes;
Exam.defaultProps = defaultProps;

const mapStateToProps = ({ exam, loader }) => ({
  examData: exam?.examData,
  loaderList: loader?.loaderList,
});

const mapDispatchToProps = {
  getDataAction: getData,
  saveTabsEnabledAction: saveTabsEnabled,
  saveMenuEnabledAction: saveMenuEnabled,
};

export default connect(mapStateToProps, mapDispatchToProps)(Exam);
