export const GetMasterListBasedOnId = (masterDataList, code) => {
  if (!masterDataList) {
    return [];
  }
  const data = masterDataList
    ?.find((m) => m.Code === code)
    ?.MasterData?.map((x) => ({
      value: x?.id,
      label: x?.Label,
      code: x?.Code,
    }))
    ?.sort((a, b) => a.Order - b.Order);
  return data;
};
export const GetMasterListBasedOnCode = (masterDataList, code) => {
  if (!masterDataList) {
    return [];
  }
  const data = masterDataList
    ?.find((m) => m.Code === code)
    ?.MasterData?.map((x) => ({
      value: x?.Code,
      label: x?.Label,
      code: x?.Code,
    }))
    ?.sort((a, b) => a.Order - b.Order);
  return data;
};

export const GetModuleMasterList = (masterDataList, code) => {
  if (!masterDataList) {
    return [];
  }
  const na = 'subjects';
  const data = masterDataList
    ?.find((m) => m.Code === code)
    ?.MasterData?.map((x) => ({
      label: x?.Label,
      value: x?.id,
      code: x?.Code,
      subjects: x?.Child?.map((x) => ({
        label: x?.Label,
        value: x?.id,
        code: x?.Code,
        topics: x?.Child?.map((x) => ({
          label: x?.Label,
          value: x?.id,
          code: x?.Code,
          subTopics: x?.Child?.map((x) => ({
            label: x?.Label,
            value: x?.id,
            code: x?.Code,
          }))?.sort((a, b) => a.Order - b.Order),
        }))?.sort((a, b) => a.Order - b.Order),
      }))?.sort((a, b) => a.Order - b.Order),
    }))
    ?.sort((a, b) => a.Order - b.Order);
  return data;
};

export const getMasterIdToType = (masterList, id) => masterList?.find((m) => m.value === id)?.code;

export const getMasterIdToLabel = (masterList, id) =>
  masterList?.find((m) => m.value === id)?.label;
