import React, { useEffect, useState } from 'react';
import {
  useIonViewWillEnter,
  useIonViewWillLeave,
  IonRefresher,
  IonRefresherContent,
  IonSearchbar,
  IonSegment,
  IonSegmentButton,
  IonLabel,
  IonLoading,
} from '@ionic/react';
import { ExamListComponent, SeriesListComponent } from '../../../components';
import { PageWrapperComponent } from '../../../../app/components';
import useApi from '../../../../../hooks/api';
import './ExamHome.scss';

const ExamHome = ({ router }) => {
  const [{ data: examResponse, isLoading: isExamLoading, error: examError }, fetchExamList] =
    useApi.get('/exam/user/details?ordering=-ModifiedAt', {}, { lazy: true });
  const [
    { data: userExamResponse, isLoading: isUserExamResponseLoading, error: userExamResponseError },
    fetchUserExamResponseList,
  ] = useApi.get('/exam/response/user?ordering=-ModifiedAt', {}, { lazy: true });
  const [
    { data: seriesResponse, isLoading: isSeriesLoading, error: seriesError },
    fetchSeriesList,
  ] = useApi.get('/exam/series/user/details?ordering=-ModifiedAt', {}, { lazy: true });
  const SEGMENT = {
    exam: 'exam',
    series: 'series',
  };
  const [examData, setExamData] = useState([]);
  const [seriesData, setSeriesData] = useState([]);
  const [pageNo, setPageNo] = useState(1);
  const [segment, setSegment] = useState(SEGMENT.exam);

  const onEnter = (isRefresh) => {
    if (segment === SEGMENT.exam && (!examData.length || isRefresh) && !isExamLoading) {
      fetchExamList();
      fetchUserExamResponseList();
    }
    if (segment === SEGMENT.series && (!seriesData.length || isRefresh) && !isSeriesLoading) {
      fetchSeriesList();
      fetchUserExamResponseList();
    }
  };

  const onLeave = () => {
    setPageNo(1);
    // setExamData([]);
    // setSeriesData([]);
    // setSegment(SEGMENT.exam);
  };

  useEffect(() => {
    onEnter();
  }, [segment]);

  useIonViewWillEnter(() => {
    onEnter();
  });

  useIonViewWillLeave(() => {
    onLeave();
  });

  useEffect(() => {
    if (examResponse && userExamResponse) {
      const examDataWithUserResponses = examResponse?.results?.map((exam) => ({
        ...exam,
        UserResponses: userExamResponse?.results?.filter((x) => x.ExamId === exam.id),
      }));

      setExamData(examDataWithUserResponses);
      setPageNo(pageNo + 1);
    }
  }, [examResponse, userExamResponse]);

  useEffect(() => {
    if (seriesResponse && userExamResponse) {
      const seriesDataWithUserResponses = seriesResponse?.results?.map((series) => ({
          ...series,
          Exams: series?.Exams?.map((exam) => ({
            ...exam,
            ...{ Exam : {...exam.Exam, UserResponses: userExamResponse?.results?.filter((x) => x.ExamId === exam.ExamId)},}
          })),
        }));

      setSeriesData(seriesDataWithUserResponses);
      setPageNo(pageNo + 1);
    }
  }, [seriesResponse, userExamResponse]);

  const onRefresh = (event) => {
    setPageNo(1);
    setExamData([]);
    setSeriesData([]);
    onEnter(true);
    event.detail.complete();
  };

  return (
    <>
      {/* {(isExamLoading|| isSeriesLoading) && <IonLoading spinner='bubbles' animated />} */}
      <PageWrapperComponent id="question" routeList={null}>
        <IonLoading
          spinner="bubbles"
          animated
          isOpen={(isExamLoading || isSeriesLoading) && !(examError || seriesError)}
          message="wait a moment"
        />

        <IonRefresher slot="fixed" onIonRefresh={onRefresh}>
          <IonRefresherContent />
        </IonRefresher>

        <IonSegment
          value={segment}
          onIonChange={(e) => {
            setSegment(e.detail.value);
          }}
        >
          <IonSegmentButton value={SEGMENT.exam}>
            <IonLabel>Exams</IonLabel>
          </IonSegmentButton>
          <IonSegmentButton value={SEGMENT.series}>
            <IonLabel>Series</IonLabel>
          </IonSegmentButton>
        </IonSegment>

        {segment === SEGMENT.exam && <ExamListComponent data={examData} router={router} />}
        {segment === SEGMENT.series && <SeriesListComponent data={seriesData} router={router} />}
      </PageWrapperComponent>
    </>
  );
};

export default ExamHome;
