import React, { useEffect, useRef, useState } from 'react';
import { connect } from 'react-redux';
import { useHistory } from 'react-router';
import { getUserClaims, resetSlice } from '../../../../reducers/userSlice/userSlice';
import './Auth.scss';
import DesktopAuth from './DesktopAuth/DesktopAuth';
import MobileAuth from './MobileAuth/MobileAuth';
import { useIonAlert, useIonViewWillEnter } from '@ionic/react';
import { isMobileDevice } from '../../../../helpers/deviceHelper';
import useApi from '../../../../hooks/api';
// import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const Auth = ({
  isAuthenticated,
  hideAppMenu,
  location,
  getUserClaimsAction,
  resetSliceAction,
}) => {
  const [showAlert, hideAlert] = useIonAlert();
  const history = useHistory();
  const [redirectedFromPath, setRedirectedFromPath] = useState('/');
  const [loginWithMobileForm, setLoginWithMobileForm] = useState(null);
  const [{ isWorking: isBasicLoginLoading }, formLogin] = useApi.post(`/token`);
  const [{ isWorking: isGoogleLoginLoading }, googleLogin] = useApi.post(`/token/google/`);
  const [{ isWorking: isFacebookLoginLoading }, facebookLogin] = useApi.post(`/token/facebook/`);

  const checkAuthState = () => {
    const lastPath = location?.state?.from?.pathname || '/';
    setRedirectedFromPath(lastPath);
    if (isAuthenticated) {
      history.push(lastPath);
    }
  };

  useIonViewWillEnter(() => {
    checkAuthState();
  });

  useEffect(() => {
    if (isAuthenticated) {
      history.push(redirectedFromPath);
    }
  }, [isAuthenticated]);

  const onSignInSubmit = (values, setSubmitting) => {
    setTimeout(async () => {
      // alert(JSON.stringify(values, null, 2));
      setSubmitting(false);
      try {
        const res = await formLogin(values);
        getUserClaimsAction(res);
      } catch (error) {
        console.error(error);
      }
    }, 400);
  };

  const onSignUpSubmit = (values, setSubmitting) => {
    setTimeout(() => {
      alert(JSON.stringify(values, null, 2));
      setSubmitting(false);
      // getUserClaimsAction();
    }, 400);
  };

  const onSocialSignIn = async (name, accessToken) => {
    switch (name) {
      case 'facebook':
        try {
          const res = await facebookLogin({
            access_token: accessToken,
            code: '',
          });
          getUserClaimsAction(res);
        } catch (error) {
          console.error(error);
        }
        break;
      case 'google':
        try {
          const res = await googleLogin({
            access_token: accessToken,
            code: '',
          });
          getUserClaimsAction(res);
        } catch (error) {
          console.error(error);
        }
        break;
      case 'linkedin':
        break;
      default:
        break;
    }
  };

  const onOtpSubmit = (otp) => {
    const code = loginWithMobileForm?.otp || '123456';
    if (code) {
      const { confirmationResult } = window;
      confirmationResult
        .confirm(code)
        .then((result) => {
          // User signed in successfully.
          const { user } = result;
          // ...
        })
        .catch((error) => {
          // User couldn't sign in (bad verification code?)
          // ...
        });
    }
  };

  return (
    <>
      {isMobileDevice ? (
        <MobileAuth
          isAuthenticated={isAuthenticated}
          hideAppMenu={hideAppMenu}
          onSignInSubmit={onSignInSubmit}
          onSignUpSubmit={onSignUpSubmit}
          onSocialSignIn={onSocialSignIn}
          loginWithMobileForm={loginWithMobileForm}
          setLoginWithMobileForm={setLoginWithMobileForm}
          // onPhoneSubmit={onPhoneSubmit}
          onOtpSubmit={onOtpSubmit}
        />
      ) : (
        <DesktopAuth
          isAuthenticated={isAuthenticated}
          hideAppMenu={hideAppMenu}
          onSignInSubmit={onSignInSubmit}
          onSignUpSubmit={onSignUpSubmit}
          onSocialSignIn={onSocialSignIn}
          loginWithMobileForm={loginWithMobileForm}
          setLoginWithMobileForm={setLoginWithMobileForm}
          // onPhoneSubmit={onPhoneSubmit}
          onOtpSubmit={onOtpSubmit}
        />
      )}
    </>
  );
};

const mapStateToProps = ({ app, user }) => ({
  plateformInfo: app?.plateformInfo,
  isAuthenticated: user?.isAuthenticated,
});

const mapDispatchToProps = {
  getUserClaimsAction: getUserClaims,
  resetSliceAction: resetSlice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Auth);
