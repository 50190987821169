export const LoaderEnum = {
  question_get: 1,
  question_add: 2,
  question_edit: 3,
  question_delete: 4,
  question_list: 5,

  master_meta_get: 11,
  master_meta_add: 12,
  master_meta_edit: 13,
  master_meta_delete: 14,
  master_meta_list: 15,

  master_data_get: 21,
  master_data_add: 22,
  master_data_edit: 23,
  master_data_delete: 24,
  master_data_list: 25,

  subset_get: 31,
  subset_add: 32,
  subset_edit: 33,
  subset_delete: 34,
  subset_list: 35,

  set_get: 41,
  set_add: 42,
  set_edit: 43,
  set_delete: 44,
  set_list: 45,

  exam_get: 51,
  exam_add: 52,
  exam_edit: 53,
  exam_delete: 54,
  exam_list: 55,

  accountUser_get: 61,
  accountUser_add: 62,
  accountUser_edit: 63,
  accountUser_delete: 64,
  accountUser_list: 65,

  accountGroup_get: 71,
  accountGroup_add: 72,
  accountGroup_edit: 73,
  accountGroup_delete: 74,
  accountGroup_list: 75,

  interview_get: 91,
  interview_add: 92,
  interview_edit: 93,
  interview_delete: 94,
  interview_list: 95,
};

/*
useEffect(() => {
    const isLoad = isModuleLoading(loaderList, [
      LoaderEnum.question_add,
      LoaderEnum.question_get,
    ]);
    SetIsLoading(isLoad);
  }, [loaderList]);
 */
export const isModuleLoading = (loaderList, values = []) => {
  // eslint-disable-next-line no-unused-expressions
  const intersection = values?.filter((element) => loaderList?.includes(element));
  return intersection?.length > 0;
};
