import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { FormattedMessage } from 'react-intl';
import './DesktopAuth.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { PageWrapperComponent } from '../../../../app/components';
import SocialSignIn from '../Common/SocialSignIn';

const DesktopAuth = ({
  onSignInSubmit,
  isAuthenticated,
  hideAppMenu,
  onSignUpSubmit,
  onSocialSignIn,
  loginWithMobileForm,
  setLoginWithMobileForm,
  onPhoneSubmit,
  onOtpSubmit,
}) => {
  useEffect(() => {
    const signUpButton = document.getElementById('signUp');
    const signInButton = document.getElementById('signIn');
    const container = document.getElementById('container');

    signUpButton.addEventListener('click', () => {
      container.classList.add('right-panel-active');
    });

    signInButton.addEventListener('click', () => {
      container.classList.remove('right-panel-active');
    });
  }, []);

  return (
    <PageWrapperComponent id="desktop-auth" hideHeader>
      <div className="desktop-auth">
        <h2>
          <FormattedMessage id="app.poweredBy" defaultMessage="Powered By: Testograph" />
        </h2>
        <div className="container" id="container">
          <SignUpForm onSignUpSubmit={onSignUpSubmit} onSocialSignIn={onSocialSignIn} />
          <SignInForm onSignInSubmit={onSignInSubmit} onSocialSignIn={onSocialSignIn} />
          <div className="overlay-container">
            <div className="overlay">
              <div className="overlay-panel overlay-left">
                <h1>Welcome Back!</h1>
                <p>To keep connected with us please login with your personal info</p>
                <button className="ghost" id="signIn">
                  Sign In
                </button>
              </div>
              <div className="overlay-panel overlay-right">
                <h1>Hello, Friend!</h1>
                <p>Enter your personal details and start journey with us</p>
                <button className="ghost" id="signUp">
                  Sign Up
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default DesktopAuth;

// const SocialSignIn = ({ onSocialSignIn }) => {
//   const onSocialSignInCLick = (socialSignInName) => {
//     onSocialSignIn(socialSignInName);
//   };

//   return (
//     <>
//       <div className="social-container">
//         <div className="social" onClick={() => onSocialSignInCLick('facebook')}>
//           <i className="fab fa-facebook-f" />
//         </div>
//         <div className="social" onClick={() => onSocialSignInCLick('google')}>
//           <i className="fab fa-google-plus-g" />
//         </div>
//         <div className="social" onClick={() => onSocialSignInCLick('linkedin')}>
//           <i className="fab fa-linkedin-in" />
//         </div>
//       </div>
//     </>
//   );
// };

const SignInForm = ({ onSignInSubmit, onSocialSignIn }) => (
  <>
    <Formik
      initialValues={{
        username: '',
        password: '',
      }}
      validationSchema={Yup.object({
        username: Yup.string().required('Required'),
        password: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSignInSubmit(values, setSubmitting);
        // setTimeout(() => {
        //   alert(JSON.stringify(values, null, 2));
        //   setSubmitting(false);
        // }, 400);
      }}
    >
      {({ errors, touched }) => (
        <div className="form-container sign-in-container">
          <Form>
            <h1>Sign in</h1>
            <SocialSignIn onSocialSignIn={onSocialSignIn} />
            <span>or use your account</span>
            <Field name="username" type="text" placeholder="username" />
            {errors.username && touched.username ? (
              <div className="text-danger">{errors.username}</div>
            ) : null}
            <Field name="password" type="password" />
            {errors.password && touched.password ? (
              <div className="text-danger">{errors.password}</div>
            ) : null}
            <div>Forgot your password?</div>
            <button type="submit">Sign In</button>
          </Form>
        </div>
      )}
    </Formik>
  </>
);

const SignUpForm = ({ onSignUpSubmit, onSocialSignIn }) => (
  <>
    <Formik
      initialValues={{
        name: '',
        username: '',
        email: '',
        password: '',
        acceptedTerms: false, // added for our checkbox
      }}
      validationSchema={Yup.object({
        name: Yup.string().max(30, 'Must be 30 characters or less').required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
        username: Yup.string().required('Required'),
        password: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
        acceptedTerms: Yup.boolean()
          .required('Required')
          .oneOf([true], 'You must accept the terms and conditions.'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSignUpSubmit(values, setSubmitting);
        // setTimeout(() => {
        //   alert(JSON.stringify(values, null, 2));
        //   setSubmitting(false);
        // }, 400);
      }}
    >
      {({ errors, touched }) => (
        <div className="form-container sign-up-container">
          <Form>
            <h1>Create Account</h1>
            <SocialSignIn onSocialSignIn={onSocialSignIn} />
            <span>or use your email for registration</span>
            <Field name="name" placeholder="john Doe" />
            {errors.name && touched.name ? <div>{errors.name}</div> : null}
            <Field name="username" placeholder="johnDoe" />
            {errors.username && touched.username ? <div>{errors.username}</div> : null}
            <Field name="email" placeholder="jane@formik.com" />
            {errors.email && touched.email ? (
              <div className="text-danger">{errors.email}</div>
            ) : null}
            <Field name="password" type="password" />
            {errors.password && touched.password ? (
              <div className="text-danger">{errors.password}</div>
            ) : null}
            <label>
              <Field name="acceptedTerms" type="checkbox" />I accept the terms and conditions
            </label>
            <button type="submit">Sign Up</button>
          </Form>
        </div>
      )}
    </Formik>
  </>
);
