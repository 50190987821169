import React from 'react';
import './HeaderWrapper.scss';
import { connect } from 'react-redux';
import { isPlatform } from '@ionic/react';
import HeaderDesktop from './Desktop/HeaderDesktop';
import HeaderMobile from './Mobile/HeaderMobile';

/* Always add Header from the Page */
const HeaderWrapper = ({ isAuthenticated, hideAppMenu, routeList, showBackButton }) => (
  <>
    {isPlatform('mobile') ? (
      <HeaderMobile
        isAuthenticated={isAuthenticated}
        hideAppMenu={hideAppMenu}
        routeList={routeList}
        showBackButton={showBackButton}
      />
    ) : (
      <HeaderDesktop
        isAuthenticated={isAuthenticated}
        hideAppMenu={hideAppMenu}
        routeList={routeList}
      />
    )}
  </>
);

const mapStateToProps = ({ app, user }) => ({
  isAuthenticated: user?.isAuthenticated,
});

const mapDispatchToProps = {};

export default connect(mapStateToProps, mapDispatchToProps)(HeaderWrapper);
