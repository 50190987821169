import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { resetSlice } from '../../../../reducers/userSlice/userSlice';
import { HeaderComponent, PageWrapperComponent } from '../../../app/components';
import './Logout.scss';

const Logout = ({ isAuthenticated, resetSliceAction }) => {
  useEffect(() => {
    if (isAuthenticated) {
      resetSliceAction();
    }
  }, []);

  return (
    <>
      <PageWrapperComponent id="logout">You have successfully logout.</PageWrapperComponent>
    </>
  );
};

const mapStateToProps = ({ app, user }) => ({
  plateformInfo: app?.plateformInfo,
  isAuthenticated: user?.isAuthenticated,
});

const mapDispatchToProps = {
  resetSliceAction: resetSlice,
};

export default connect(mapStateToProps, mapDispatchToProps)(Logout);
