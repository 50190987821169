import { createSlice } from '@reduxjs/toolkit';
import { toast } from 'react-toastify';
import initialState from './initialState';

const loaderSlice = createSlice({
  name: 'loader',
  initialState,
  reducers: {
    updateLoaderList(state, { payload }) {
      state.loaderList = payload;
    },
  },
});

export const { updateLoaderList } = loaderSlice.actions;

export default loaderSlice.reducer;

// Call when the new page/route is opened
// EXAMPLE: dispatch(InitLoader());
export const InitLoader = (val) => async (dispatch, getState) => {
  try {
    dispatch(updateLoaderList([]));
  } catch (e) {
    const err = e;
    console.log(err);
  }
};

// Call to register the loader before api call
// EXAMPLE: dispatch(RegisterLoader(LoaderEnum.question_add));
export const RegisterLoader = (val) => async (dispatch, getState) => {
  try {
    const { loader } = getState();
    if (loader?.loaderList?.includes(val)) return;
    const l = loader?.loaderList?.slice();
    l.push(val);
    dispatch(updateLoaderList(l));
  } catch (e) {
    const err = e;
    console.log(err);
  }
};

// Call to remove the loader after api call or any api error
// EXAMPLE: dispatch(DeRegisterLoader(LoaderEnum.question_add));
export const DeRegisterLoader = (val, msg) => async (dispatch, getState) => {
  try {
    const { loader } = getState();
    const l = loader.loaderList.slice();
    const index = l.indexOf(val);
    if (index !== -1) {
      l.splice(index, 1);
    }
    dispatch(updateLoaderList(l));

    if (msg?.show && msg?.message) {
      const option = {
        position: 'top-right',
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      };
      switch (msg?.type) {
        case 'success':
          toast.success(msg?.message, option);
          break;
        case 'warning':
          toast.warning(msg?.message, option);
          break;
        case 'error':
          toast.error(msg?.message, option);
          break;
      }
    }
  } catch (e) {
    const err = e;
    console.log(err);
  }
};
