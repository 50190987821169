import React from 'react';
import { hot } from 'react-hot-loader/root';
// import { unstable_createRoot } from 'react-dom';
import { Provider } from 'react-redux';
import ReactDOM from 'react-dom/client';
import store, { persistor } from './config/store';
import './index.scss';
import App from './App';
import reportWebVitals from './reportWebVitals';
import * as serviceWorker from './serviceWorker';
import { PersistGate } from 'redux-persist/integration/react';

// Put any other imports below so that CSS from your
// components takes precedence over default styles.
const StoreRoot = () => (
  <Provider store={store}>
    <PersistGate loading={null} persistor={persistor}>
      <App />
    </PersistGate>
  </Provider>
);

const StoreHot = hot(StoreRoot);
const container = document.getElementById('root');

if (container) {
  const root = ReactDOM.createRoot(container);
  root.render(<StoreHot />); // real dom
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
function sendToAnalytics(metric) {
  console.log('metric', metric);
  // const body = JSON.stringify(metric);
  // const url = 'https://example.com/analytics';

  // // Use `navigator.sendBeacon()` if available, falling back to `fetch()`
  // if (navigator.sendBeacon) {
  //   navigator.sendBeacon(url, body);
  // } else {
  //   fetch(url, { body, method: 'POST', keepalive: true });
  // }

  // /** If you use Google Analytics, use the id value to make
  //  * it easier to construct metric distributions manually
  //  * (to calculate percentiles, etc…). */
  // ga('send', 'event', {
  //   eventCategory: 'Web Vitals',
  //   eventAction: name,
  //   eventValue: Math.round(name === 'CLS' ? value * 1000 : value), // values must be integers
  //   eventLabel: id, // id unique to current page load
  //   nonInteraction: true, // avoids affecting bounce rate
  // });
}
reportWebVitals(sendToAnalytics);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
// serviceWorker.register();

// const swConfig = {
//   // When a new SW has been installed
//   onUpdate: async (registration) => {
//     await registration.unregister();
//     registration.waiting.postMessage({ type: 'SKIP_WAITING' });
//     window.location.reload();
//   },
// };
// serviceWorker.register(swConfig);

serviceWorker.unregister();
