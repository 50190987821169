import React from 'react';
import { IonContent, IonPage } from '@ionic/react';
import './PageWrapper.scss';
import { HeaderComponent } from '..';

const PageWrapper = ({ id, routeList, children, hideHeader, showBackButton }) => (
  <>
    <IonPage id={`page-${id}`}>
      {/* Always add Header from the Page */}
      <IonContent fullscreen scrollY>
        {!hideHeader && <HeaderComponent routeList={routeList} showBackButton={showBackButton} />}
        {children}
      </IonContent>
    </IonPage>
  </>
);

export default PageWrapper;
