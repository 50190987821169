import { combineReducers } from '@reduxjs/toolkit';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import UserReducer from '../reducers/userSlice/userSlice';
import MasterReducer from '../reducers/masterSlice/masterSlice';
import CommonReducer from '../reducers/commonSlice/commonSlice';
import LoaderReducer from '../reducers/loaderSlice/loaderSlice';
import InterviewReducer from '../reducers/interviewSlice/interviewSlice';
import ExamReducer from '../reducers/examSlice/examSlice';
import AppReducer from '../reducers/appSlice/appSlice';
import PersistReducer from '../reducers/persistSlice/persistSlice';

const userPersistConfig = {
  key: 'user',
  storage,
  blacklist: ['somethingTemporary'],
};

const persistPersistConfig = {
  key: 'persist',
  storage,
  blacklist: ['somethingTemporary'],
};

const masterPersistConfig = {
  key: 'master',
  storage,
  whitelist: ['masterList', 'masterDataList'],
};

// const interviewPersistConfig = {
//   key: 'interview',
//   storage,
//   whitelist: [],
// };

const rootReducer = combineReducers({
  app: AppReducer,
  user: persistReducer(userPersistConfig, UserReducer),
  master: persistReducer(masterPersistConfig, MasterReducer),
  common: CommonReducer,
  loader: LoaderReducer,
  persist: persistReducer(persistPersistConfig, PersistReducer),
  interview: InterviewReducer,
  exam: ExamReducer,
});

export default rootReducer;
