import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { localizedMessageList, PlateformCd } from '../../helpers/typeCodes';
// import configDataProd from "../../resources/AppConfigProd.json";
// import configDataDev from "../../resources/AppConfigDev.json";

const app = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setConfig(state, { payload }) {
      return { ...state, ...payload };
    },
    setLocalization(state, { payload }) {
      state.localization = payload;
    },
    setDarkMode(state, { payload }) {
      state.darkMode = payload;
    },
    setTabsEnabled(state, { payload }) {
      state.tabsEnabled = payload;
    },
    setMenuEnabled(state, { payload }) {
      state.menuEnabled = payload;
    },
  },
});

export const { setConfig, setLocalization, setDarkMode, setTabsEnabled, setMenuEnabled } =
  app.actions;

export default app.reducer;

export const initConfig = (cb) => async (dispatch) => {
  try {
    const configFileData = null;
    const env = 'dev';
    // if (env === 'prod') {
    //   configFileData = configDataProd;
    // } else {
    //   configFileData = configDataDev;
    // }
    dispatch(setConfig(configFileData));
    if (cb) {
      cb();
    }
  } catch (e) {
    const err = e;
    console.log(err);
  }
};

export const saveLocalization = (locale) => async (dispatch) => {
  const localization = { locale: 'en', messages: localizedMessageList[locale] };
  dispatch(setLocalization(localization));
};

export const saveDarkMode = (darkMode) => async (dispatch) => {
  dispatch(setDarkMode(darkMode));
};
export const saveTabsEnabled = (tabsEnabled) => async (dispatch) => {
  dispatch(setTabsEnabled(tabsEnabled));
};
export const saveMenuEnabled = (menuEnabled) => async (dispatch) => {
  dispatch(setMenuEnabled(menuEnabled));
};
