import React, { useEffect } from 'react';
import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';
import { GoogleLogin } from 'react-google-login';
import { FACEBOOK_APP_ID, GOOGLE_CLIENT_ID } from '../../../../../config/config';
import { SOCIAL_PROVIDER } from '../../../../../constants/constants';

const SocialSignIn = ({ onSocialSignIn }) => {
  const responseGoogle = (res) => {
    if (res?.accessToken) {
      onSocialSignIn(SOCIAL_PROVIDER.GOOGLE, res?.accessToken);
    } else {
      alert('Some error occured, kindly try again');
    }
  };
  const responseFacebook = (res) => {
    if (res?.accessToken) {
      onSocialSignIn(SOCIAL_PROVIDER.FACEBOOK, res?.accessToken);
    } else {
      alert('Some error occured, kindly try again');
    }
  };

  return (
    <>
      <div className="social-container">
        <div className="social">
          <FacebookLogin
            appId={FACEBOOK_APP_ID}
            fields="name,email,picture"
            callback={responseFacebook}
            render={(renderProps) => (
              <i className="fab fa-facebook-f" onClick={renderProps.onClick} />
            )}
          />
        </div>
        <div>
          <GoogleLogin
            className="social"
            clientId={GOOGLE_CLIENT_ID}
            render={(renderProps) => (
              <i
                className="fab fa-google-plus-g"
                onClick={renderProps.onClick}
                disabled={renderProps.disabled}
              />
            )}
            buttonText="Login"
            onSuccess={responseGoogle}
            onFailure={responseGoogle}
            cookiePolicy="single_host_origin"
          />
        </div>
        {/* <div className="social" onClick={() => onSocialSignInCLick('linkedin')}>
          <i className="fab fa-linkedin-in" />
        </div> */}
      </div>
    </>
  );
};

export default SocialSignIn;
