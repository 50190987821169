import { toast } from 'react-toastify';
import { ROUTES } from '../../helpers/RouteHelper';
import history from '../../utils/browserHistory';
import store from '../../config/store';
import { resetSlice } from '../../reducers/userSlice/userSlice';

const handleError = (error) => {
  const option = {
    position: 'top-right',
    autoClose: 1500,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  };
  if (error?.apiError) {
    option.toastId = 'api-err-val';
    toast.error('Some validations are failed, Kindly correct them and submit again.', option);
  } else if (error?.isUnAuthUser) {
    store.dispatch(resetSlice());
    history.push(ROUTES.AUTH);
    option.toastId = 'api-err-sess';
    // debugger
    toast.error('Session Expired, Kindly login again.', option);
  } else {
    option.toastId = 'api-err-unk';
    toast.error(JSON.stringify(error, null, 2), option);
  }
};

export { handleError };
