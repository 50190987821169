import axios from 'axios';
import axiosRetry from 'axios-retry';
import { getBackendApiUrl } from '../config/config';
import { apiDefaults } from '../constants/constants';
import { removeStoredAuthToken } from '../utils/authToken';
import browserHistory from '../utils/browserHistory';

const apiV1 = (withAuth = true, version = 'V1', enableRetry = false) => {
  // authenticate user if jwt token is in localstore
  const client = axios.create({
    baseURL: `${getBackendApiUrl(version)}/`,
  });
  if (localStorage.getItem('authToken') && withAuth) {
    client.defaults.headers.common.Authorization = `Bearer ${localStorage.getItem('authToken')}`;
  }
  // Add a request interceptor
  client.interceptors.request.use(
    (config) => /* Do something before request is sent */ config,
    (error) => Promise.reject(error), // Do something with request error
  );

  // Add a response interceptor
  client.interceptors.response.use(
    (response) =>
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      response,
    (error) => {
      if (error.response) {
        if (error?.response?.status === 401) {
          removeStoredAuthToken();
          browserHistory.push('/logout');
        } else if (error?.response?.status === 400) {
          return Promise.reject({
            apiError: error?.response?.data,
          });
        } else {
          return Promise.reject(error.response.data);
        }
      } else {
        return Promise.reject(apiDefaults.error);
      }

      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // // Do something with response error
      // if (!error?.status && !error?.response?.status) {
      //   // network error
      //   alert(
      //     'There is some unhandled error has occured, maybe API is down or Network is not available.',
      //   );
      // }
      // if (error?.response?.status === 401) {
      //   // localStorage.removeItem("userName");
      //   // localStorage.removeItem("userId");
      //   // localStorage.removeItem("token");
      // }
      return Promise.reject(error);
    },
  );

  if (enableRetry) {
    // Custom retry delay
    // Use then and catch with this
    return axiosRetry(client, {
      retryDelay: (retryCount) => retryCount * 1000,
    });
  }
  return client;
};

export { apiV1 };
