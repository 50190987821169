import React from 'react';
import { RouteComponentProps, withRouter, useLocation } from 'react-router';
import { connect } from 'react-redux';
import {
  IonContent,
  IonIcon,
  IonItem,
  IonLabel,
  IonList,
  IonListHeader,
  IonMenu,
  IonMenuToggle,
  IonToggle,
} from '@ionic/react';
import {
  calendarOutline,
  hammer,
  moonOutline,
  help,
  informationCircleOutline,
  logIn,
  logOut,
  mapOutline,
  peopleOutline,
  person,
  personAdd,
} from 'ionicons/icons';

// import { connect } from '../data/connect';
import { saveDarkMode } from '../../../../../reducers/appSlice/appSlice';

import './SideMenuMobile.scss';
import { ROUTES } from '../../../../../helpers/RouteHelper';

const routes = {
  appPages: [
    { title: 'Home', path: ROUTES.HOME, icon: calendarOutline },
    { title: 'Speakers', path: '/home', icon: peopleOutline },
    { title: 'Map', path: '/home', icon: mapOutline },
    { title: 'About', path: '/home', icon: informationCircleOutline },
  ],
  loggedInPages: [
    { title: 'Account', path: '/home', icon: person },
    { title: 'Support', path: '/home', icon: help },
    { title: 'Logout', path: '/home', icon: logOut },
  ],
  loggedOutPages: [
    { title: 'Login', path: '/home', icon: logIn },
    { title: 'Support', path: '/home', icon: help },
    { title: 'Signup', path: '/home', icon: personAdd },
  ],
};

const Menu = ({ darkMode, history, isAuthenticated, saveDarkModeAction, menuEnabled }) => {
  // const location = useLocation();

  function renderlistItems(list) {
    return list
      .filter((route) => !!route.path)
      .map((p) => (
        <IonMenuToggle key={p.title} auto-hide="false">
          <IonItem
            detail={false}
            routerLink={p.path}
            routerDirection="none"
            // className={location.pathname.startsWith(p.path) ? 'selected' : undefined}
          >
            <IonIcon slot="start" icon={p.icon} />
            <IonLabel>{p.title}</IonLabel>
          </IonItem>
        </IonMenuToggle>
      ));
  }

  return (
    <IonMenu type="overlay" disabled={!menuEnabled} contentId="main">
      <IonContent forceOverscroll={false}>
        <IonList lines="none">
          <IonListHeader>Conference</IonListHeader>
          {renderlistItems(routes.appPages)}
        </IonList>
        <IonList lines="none">
          <IonListHeader>Account</IonListHeader>
          {isAuthenticated
            ? renderlistItems(routes.loggedInPages)
            : renderlistItems(routes.loggedOutPages)}
          <IonItem>
            <IonIcon slot="start" icon={moonOutline} />
            <IonLabel>Dark Mode</IonLabel>
            <IonToggle checked={darkMode} onClick={() => saveDarkModeAction(!darkMode)} />
          </IonItem>
        </IonList>
        <IonList lines="none">
          <IonListHeader>Tutorial</IonListHeader>
          <IonItem
            button
            onClick={() => {
              history.push('/home');
            }}
          >
            <IonIcon slot="start" icon={hammer} />
            Show Tutorial
          </IonItem>
        </IonList>
      </IonContent>
    </IonMenu>
  );
};

const mapStateToProps = ({ app, user }) => ({
  darkMode: app.darkMode,
  isAuthenticated: user.isLoggedin,
  menuEnabled: app.menuEnabled,
});

const mapDispatchToProps = {
  saveDarkModeAction: saveDarkMode,
};

export default connect(mapStateToProps, mapDispatchToProps)(Menu);
