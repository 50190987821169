const firebaseConfig = {
  apiKey: 'AIzaSyB0SKNJt5wGN9Pht705tycEFZVtO6gPpBU',
  authDomain: 'testographapp.firebaseapp.com',
  projectId: 'testographapp',
  storageBucket: 'testographapp.appspot.com',
  messagingSenderId: '584351203918',
  appId: '1:584351203918:web:706c8c6beee2e4c6a9b91f',
  measurementId: 'G-9Q7W1Z41EB',
};

const EXAM_SYNC_IN_MS = 30000;

const EXAM_START_DELAY = 2500;

export { firebaseConfig, EXAM_SYNC_IN_MS, EXAM_START_DELAY };
