import React, { useEffect, useState } from 'react';
import { PropTypes } from 'prop-types';
import { useIonViewWillEnter } from '@ionic/react';
import { connect } from 'react-redux';
import { saveMenuEnabled, saveTabsEnabled } from '../../../../../reducers/appSlice/appSlice';
import { PageWrapperComponent } from '../../../../app/components';

const ExamTxn = ({ saveTabsEnabledAction, saveMenuEnabledAction }) => {
  useIonViewWillEnter(() => {
    // This will change the Router outlet to hide the tabs/sidebar
    saveTabsEnabledAction(false);
    saveMenuEnabledAction(false);
  });

  return (
    <PageWrapperComponent id="exam" hideHeader>
      Loading
    </PageWrapperComponent>
  );
};

const mapDispatchToProps = {
  saveTabsEnabledAction: saveTabsEnabled,
  saveMenuEnabledAction: saveMenuEnabled,
};

export default connect(null, mapDispatchToProps)(ExamTxn);
