import { localizedMessageList } from '../../helpers/typeCodes';

export default {
  loading: false,
  error: null,
  localization: { locale: 'en', messages: localizedMessageList.en },
  referrer: '',
  darkMode: false,
  menuEnabled: true,
  tabsEnabled: true,
};
