const getAnalyticsUrl = (env = 'dev') => 'https://localhost:3000/analytics';

// const getBackendApiUrl = (version = 'V1', env = 'dev') => 'https://testograph-backend-stg.herokuapp.com';
// const getBackendApiUrl = (version = 'V1', env = 'dev') => 'http://127.0.0.1:8000';
// const getBackendApiUrl = (version = 'V1', env = 'dev') => 'http://127.0.0.1:8005';
// const getBackendApiUrl = (version = 'V1', env = 'dev') => 'http://13.235.68.178:8001';
// const getBackendApiUrl = (version = 'V1', env = 'dev') => 'http://13.233.195.59:8001';
// const getBackendApiUrl = (version = 'V1', env = 'dev') => 'https://api.testograph.com';
const getBackendApiUrl = (version = 'V1', env = 'dev') => process.env.REACT_APP_API_URL;

export { getAnalyticsUrl, getBackendApiUrl };

export const DEFAULT_PAGE_TITLE = 'Testograph';
export const PREFIX_PAGE_TITLE = 'Testograph ';

export const FACEBOOK_APP_ID = '553766495278052';
export const GOOGLE_CLIENT_ID =
  '584351203918-7meqe46441k8nk4s3obacnumid7gggts.apps.googleusercontent.com';
