import { IonContent, IonPage } from '@ionic/react';
import React, { useEffect, useRef, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import './MobileAuth.scss';
import { Formik, Form, Field } from 'formik';
import * as Yup from 'yup';
import { PageWrapperComponent } from '../../../../app/components';
import SocialSignIn from '../Common/SocialSignIn';
import { getAuth, RecaptchaVerifier, signInWithPhoneNumber } from 'firebase/auth';

const MobileAuth = ({
  onSignInSubmit,
  isAuthenticated,
  hideAppMenu,
  onSignUpSubmit,
  onSocialSignIn,
  loginWithMobileForm,
  setLoginWithMobileForm,
  // onPhoneSubmit,
  onOtpSubmit,
}) => {
  const [showSignIn, setShowSignIn] = useState(true);

  return (
    <PageWrapperComponent id="mobile-auth" hideHeader>
      <div className="mobile-auth">
        {/* <h2>
            <FormattedMessage
              id="app.poweredBy"
              defaultMessage="Powered By: Testograph"
            />
          </h2> */}
        <div className="container" id="container">
          {showSignIn ? (
            <SignInForm
              onSignInSubmit={onSignInSubmit}
              onSocialSignIn={onSocialSignIn}
              setShowSignIn={setShowSignIn}
              onOtpSubmit={onOtpSubmit}
              setLoginWithMobileForm={setLoginWithMobileForm}
            />
          ) : (
            <SignUpForm
              onSignUpSubmit={onSignUpSubmit}
              onSocialSignIn={onSocialSignIn}
              setShowSignIn={setShowSignIn}
            />
          )}
        </div>
      </div>
    </PageWrapperComponent>
  );
};

export default MobileAuth;

const SignInForm = ({
  setShowSignIn,
  onSocialSignIn,
  onSignInSubmit,
  onOtpSubmit,
  setLoginWithMobileForm,
}) => {
  const [isLoginWithMobile, setIsLoginWithMobile] = useState(false);
  const [isPhoneSubmitted, setIsPhoneSubmitted] = useState(false);
  const auth = useRef(null);

  const onPhoneSubmit = (phone) => {
    const appVerifier = window.recaptchaVerifier;
    signInWithPhoneNumber(auth.current, phone, appVerifier)
      .then((confirmationResult) => {
        // SMS sent. Prompt user to type the code from the message, then sign the
        // user in with confirmationResult.confirm(code).
        window.confirmationResult = confirmationResult;
        // ...
      })
      .catch((error) => {
        // Error; SMS not sent
        // ...
      });
  };

  useEffect(() => {
    // const aauth = getAuth();
    // window.recaptchaVerifier = new RecaptchaVerifier(
    //   'sign-in-button',
    //   {
    //     size: 'invisible',
    //     callback: (response) => {
    //       // reCAPTCHA solved, allow signInWithPhoneNumber.
    //       setIsPhoneSubmitted(true);
    //     },
    //   },
    //   aauth,
    // );
    // auth.current = aauth;
  }, []);

  return (
    <>
      <Formik
        initialValues={{
          username: '',
          password: '',
          phone: '',
          otp: '',
        }}
        validationSchema={Yup.object({
          username: isLoginWithMobile ? Yup.string() : Yup.string().required('Required'),
          password: isLoginWithMobile
            ? Yup.string()
            : Yup.string().max(50, 'Must be 50 characters or less').required('Required'),

          phone: isLoginWithMobile ? Yup.string().required('Required') : Yup.string(),
          otp: isLoginWithMobile
            ? Yup.string().max(6, 'Must be 6 characters or less')
            : Yup.string(),
        })}
        onSubmit={(values, { setSubmitting }) => {
          const formValue = values;
          if (isLoginWithMobile) {
            delete formValue.email;
            delete formValue.password;
            setLoginWithMobileForm(formValue);
            if (!isPhoneSubmitted) {
              onPhoneSubmit(formValue?.phone);
            } else {
              onOtpSubmit(formValue?.otp);
            }
          } else {
            delete formValue.phone;
            delete formValue.otp;
            onSignInSubmit(values, setSubmitting);
          }
        }}
      >
        {({ errors, touched }) => (
          <div className="form-container sign-in-container">
            <Form>
              <h1>Sign in</h1>
              <SocialSignIn onSocialSignIn={onSocialSignIn} />

              {isLoginWithMobile ? (
                <>
                  <span>or use your mobile number</span>
                  <Field name="phone" type="text" placeholder="phone" disabled={isPhoneSubmitted} />
                  {errors.phone && touched.phone ? (
                    <div className="text-danger">{errors.phone}</div>
                  ) : null}

                  {isPhoneSubmitted && (
                    <>
                      <Field name="otp" type="otp" />
                      {errors.otp && touched.otp ? (
                        <div className="text-danger">{errors.otp}</div>
                      ) : null}
                    </>
                  )}
                </>
              ) : (
                <>
                  <span>or use your account</span>
                  <Field name="username" type="text" placeholder="username" />
                  {errors.username && touched.username ? (
                    <div className="text-danger">{errors.username}</div>
                  ) : null}
                  <Field name="password" type="password" />
                  {errors.password && touched.password ? (
                    <div className="text-danger">{errors.password}</div>
                  ) : null}
                </>
              )}
              {!isLoginWithMobile && <label>Forgot your password?</label>}
              <button type="submit" disabled={isPhoneSubmitted}>
                {isLoginWithMobile
                  ? isPhoneSubmitted
                    ? 'Submit Otp'
                    : 'Submit Phone Number'
                  : 'Sign In'}
              </button>
              {/* <button type="submit" id="sign-in-button" disabled={!isPhoneSubmitted}>
                Submit Otp
              </button>
              <label
                onClick={() => {
                  setIsLoginWithMobile(!isLoginWithMobile);
                  setIsPhoneSubmitted(false);
                  setLoginWithMobileForm(null);
                }}
              >
                {' '}
                {`Login With ${isLoginWithMobile ? 'Email' : 'Phone'}`}
              </label> */}
              <label onClick={() => setShowSignIn(false)}> Don't have Account?</label>
            </Form>
          </div>
        )}
      </Formik>
    </>
  );
};

const SignUpForm = ({ setShowSignIn, onSocialSignIn, onSignUpSubmit }) => (
  <>
    <Formik
      initialValues={{
        name: '',
        email: '',
        password: '',
        acceptedTerms: false, // added for our checkbox
      }}
      validationSchema={Yup.object({
        name: Yup.string().max(30, 'Must be 30 characters or less').required('Required'),
        email: Yup.string().email('Invalid email address').required('Required'),
        password: Yup.string().max(50, 'Must be 50 characters or less').required('Required'),
        acceptedTerms: Yup.boolean()
          .required('Required')
          .oneOf([true], 'You must accept the terms and conditions.'),
      })}
      onSubmit={(values, { setSubmitting }) => {
        onSignUpSubmit(values, setSubmitting);
        // setTimeout(() => {
        //   alert(JSON.stringify(values, null, 2));
        //   setSubmitting(false);
        // }, 400);
      }}
    >
      {({ errors, touched }) => (
        <div className="form-container sign-in-container">
          <Form>
            <h1>Create Account</h1>
            <SocialSignIn onSocialSignIn={onSocialSignIn} />
            <span>or use your email for registration</span>
            <Field name="name" placeholder="john Doe" />
            {errors.name && touched.name ? <div>{errors.name}</div> : null}
            <Field name="email" placeholder="jane@formik.com" />
            {errors.email && touched.email ? (
              <div className="text-danger">{errors.email}</div>
            ) : null}
            <Field name="password" type="password" />
            {errors.password && touched.password ? (
              <div className="text-danger">{errors.password}</div>
            ) : null}
            <label>
              <Field name="acceptedTerms" type="checkbox" />I accept the terms and conditions
            </label>
            <button type="submit">Sign Up</button>
            <label onClick={() => setShowSignIn(true)}> Already have Account?</label>
          </Form>
        </div>
      )}
    </Formik>
  </>
);
