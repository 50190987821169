import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';
import { deleteSlice, getSlice, postAndPutSlice } from '../commonSliceMethods';

const interviewSlice = createSlice({
  name: 'interview',
  initialState,
  reducers: {
    updateIsLoading(state, { payload }) {
      state.isLoading = payload;
    },
    updateData(state, { payload }) {
      state[payload.key] = payload.data;
    },
  },
});

export const { updateIsLoading, updateData } = interviewSlice.actions;

export default interviewSlice.reducer;

// getData(`/interview/mcq/create/${branchId}`, "BranchCMSData" , LoaderEnum.BranchCms)
export const getData = (url, stateKey, loaderId) => async (dispatch, getState) => {
  getSlice(dispatch, getState, updateData, url, stateKey, loaderId);
};

export const postData =
  (url, data, stateKey, loaderId, messages = null, isPut = false) =>
  async (dispatch, getState) => {
    postAndPutSlice(dispatch, getState, updateData, url, data, stateKey, loaderId, messages, isPut);
  };

export const deleteData = (url, stateKey, loaderId) => async (dispatch, getState) => {
  deleteSlice(dispatch, getState, updateData, url, stateKey, loaderId);
};

export const setStateData = (stateKey, stateValue) => async (dispatch, getState) => {
  try {
    const d = { key: stateKey, data: stateValue };
    dispatch(updateData(d));
  } catch (e) {
    const err = e;
    console.log(err);
  }
};
