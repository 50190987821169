import { apiV1 } from './config';

export const getAuthV1 = (url) => apiV1(true, 'V1').get(url);
export const postAuthV1 = (url, data) => apiV1(true, 'V1').post(url, data);
export const putAuthV1 = (url, data) => apiV1(true, 'V1').put(url, data);
export const deleteAuthV1 = (url) => apiV1(true, 'V1').delete(url);

export const getUnAuthV1 = (url) => apiV1(false, 'V1').get(url);
export const postUnAuthV1 = (url, data) => apiV1(false, 'V1').post(url, data);
export const putUnAuthV1 = (url, data) => apiV1(false, 'V1').put(url, data);
export const deleteUnAuthV1 = (url) => apiV1(false, 'V1').delete(url);
