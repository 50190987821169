import { deleteAuthV1, getAuthV1, postAuthV1, putAuthV1 } from '../services/http.service';
import { DeRegisterLoader, RegisterLoader } from './loaderSlice/loaderSlice';

export const getSlice = async (dispatch, getState, updateData, url, stateKey, loaderId) => {
  try {
    dispatch(RegisterLoader(loaderId));
    const res = await getAuthV1(url);
    if ([200, 201].includes(res?.status)) {
      if (stateKey) {
        const d = { key: stateKey, data: res?.data };
        dispatch(updateData(d));
      }
      dispatch(DeRegisterLoader(loaderId));
    }
  } catch (e) {
    const err = e;
    // console.log(err);
    dispatch(DeRegisterLoader(loaderId));
  }
};

export const postAndPutSlice = async (
  dispatch,
  getState,
  updateData,
  url,
  data,
  stateKey,
  loaderId,
  messages,
  isPut,
) => {
  try {
    dispatch(RegisterLoader(loaderId));
    const res = isPut ? await putAuthV1(url, data) : await postAuthV1(url, data);
    if ([200, 201].includes(res?.status)) {
      if (stateKey) {
        const d = { key: stateKey, data: res?.data };
        dispatch(updateData(d));
      }
      dispatch(
        DeRegisterLoader(loaderId, {
          type: 'success',
          show: messages?.show,
          message: messages?.success_message,
        }),
      );
    }
  } catch (e) {
    const err = e;
    // console.log(err);
    dispatch(
      DeRegisterLoader(loaderId, {
        type: 'error',
        show: messages?.show,
        message: `${err?.message} : ${JSON.stringify(err?.response?.data, null, 2)}`,
      }),
    );
  }
};

export const deleteSlice = async (dispatch, getState, updateData, url, stateKey, loaderId) => {
  try {
    dispatch(RegisterLoader(loaderId));
    const res = await deleteAuthV1(url);
    if ([200, 201, 204].includes(res?.status)) {
      if (stateKey) {
        const d = { key: stateKey, data: res?.data };
        dispatch(updateData(d));
      }
      dispatch(DeRegisterLoader(loaderId));
    }
  } catch (e) {
    const err = e;
    // console.log(err);
    dispatch(DeRegisterLoader(loaderId));
  }
};
