import { IonContent, IonPage, IonItem, IonLabel } from '@ionic/react';
import React from 'react';

import { PageWrapperComponent } from '../../components';
import './Loading.scss';

const Loading = () => (
  <PageWrapperComponent id="loading">
    <div id="load">
      <div>G</div>
      <div>N</div>
      <div>I</div>
      <div>D</div>
      <div>A</div>
      <div>O</div>
      <div>L</div>
    </div>
  </PageWrapperComponent>
);

export default Loading;
