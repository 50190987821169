// import messagesUnitedStates from "../locales/en-US.json";
// import messagesCanada from "../locales/en-CA.json";
// import messagesAustralia from "../locales/en-AU.json";
import messagesHindi from '../locales/hi.json';
import messagesDefault from '../locales/en.json';

export const localizedMessageList = {
  en: messagesDefault,
  hi: messagesHindi,
  // "en-US": messagesUnitedStates,
  // "en-CA": messagesCanada,
  // "en-AU": messagesAustralia,
  // "en-NZ": messagesNewZealand,
};

const enviroments = {
  dev: 'dev',
  prod: 'prod',
};

export const PlateformCd = {
  IOS: 'ios',
  IPAD: 'ipad',
  IPHONE: 'iphone',
  ANDROID: 'android',
  PHABLET: 'phablet',
  TABLET: 'tablet',
  CORDOVA: 'cordova',
  CAPACITOR: 'capacitor',
  ELECTRON: 'electron',
  PWA: 'pwa',
  MOBILE: 'mobile',
  MOBILEWEB: 'mobileweb',
  DESKTOP: 'desktop',
  HYBRID: 'hybrid',
};

export const editorTypeCode = {
  TEXT_ONLY: 'NTED',
  DRAFT_EDITOR: 'DRTE',
};

export const questionTypeCode = {
  TRUE_FALSE: 'TRFL',
  MCQ: 'MCQS',
};

export const masterCodes = {
  DIFFICULTY: 'DFTY',
  MODULE: 'MDUL',
  TAGS: 'TAGS',
  EDITOR_TYPE: 'EDRT',
  QUESTION_MCQ_TYPE: 'QUST',
  QUESTION_MCQ_STATUS: 'QUSSs',
  EXAM_TYPE: 'EXMT',
  USER_PERMISSION: 'PERM',
};
