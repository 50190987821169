// import Experiment from './Experiment/Experiment';
import { lazy } from 'react';

// import InterviewHome from "./InterviewHome/InterviewHome";
// import InterviewQuestion from "./InterviewQuestion/InterviewQuestion";

const InterviewHome = lazy(() => import('./Native/InterviewHome/InterviewHome'));
const InterviewQuestion = lazy(() => import('./Native/InterviewQuestion/InterviewQuestion'));

// eslint-disable-next-line import/prefer-default-export
export {
  InterviewHome as InterviewHomePageNative,
  InterviewQuestion as InterviewQuestionPageNative,
};

// export {
//     InterviewHome as InterviewHomePage,
//     InterviewQuestion as InterviewQuestionPage,
//  };
