import React from 'react';
import ContentLoader from 'react-content-loader';
import './ThreeDotsLoader.scss';

const ThreeDotsLoader = ({ show }) => (
  <>
    {show && (
      <div id="overlay">
        <div className="center">
          <ContentLoader
            // viewBox="0 0 400 160"
            title="Loading please wait..."
            height="100%"
            width="100%"
            backgroundColor="transparent"
          >
            <circle cx="10" cy="50%" r="8" />
            <circle cx="50" cy="50%" r="8" />
            <circle cx="90" cy="50%" r="8" />
            <circle cx="130" cy="50%" r="8" />
            <circle cx="170" cy="50%" r="8" />
          </ContentLoader>
        </div>
      </div>
    )}
  </>
);

export default ThreeDotsLoader;
