import { IonContent, IonPage, IonItem, IonLabel } from '@ionic/react';
import React from 'react';
import { useHistory } from 'react-router';

import { PageWrapperComponent } from '../../components';
import './NotFound.scss';

const NotFound = () => {
  const history = useHistory();
  return (
    <PageWrapperComponent id="not-found">
      <section className="page_404">
        <div className="container">
          <div className="row">
            <div className="col-sm-12 ">
              <div className="col-sm-10 col-sm-offset-1  text-center">
                <div className="four_zero_four_bg">
                  <h1 className="text-center ">404</h1>
                </div>

                <div className="contant_box_404">
                  <h3 className="h2">Look like you're lost</h3>

                  <p>the page you are looking for not available!</p>

                  <button
                    className="link_404"
                    onClick={() => {
                      history.push('/home');
                    }}
                  >
                    Go to Home
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </PageWrapperComponent>
  );
};

export default NotFound;
