import { getBackendApiUrl } from '../config/config';
import { getStoredAuthToken } from '../utils/authToken';

export const apiDefaults = {
  baseURL: getBackendApiUrl(),
  headers: () => ({
    'Content-Type': 'application/json',
    Authorization: getStoredAuthToken() ? `Bearer ${getStoredAuthToken()}` : undefined,
  }),
  error: {
    code: 'INTERNAL_ERROR',
    message: 'Something went wrong. Please check your internet connection or contact our support.',
    status: 503,
    data: {},
  },
};

export const SOCIAL_PROVIDER = {
  FACEBOOK: 'facebook',
  GOOGLE: 'google',
};
