// import Experiment from './Experiment/Experiment';
import { lazy } from 'react';

// import Exam from './Exam/Exam';
// import ExamList from './ExamList/ExamList';

const Basic = lazy(() => import('./Templates/Basic/Basic'));
const ExamList = lazy(() => import('./ExamList/ExamList'));
const ExamInfoModal = lazy(() => import('./ExamInfoModal/ExamInfoModal'));
const ExamResult = lazy(() => import('./ExamResult/ExamResult'));

const SeriesList = lazy(() => import('./SeriesList/SeriesList'));
const SeriesInfoModal = lazy(() => import('./SeriesInfoModal/SeriesInfoModal'));

// eslint-disable-next-line import/prefer-default-export
export {
  Basic as BasicExamTemplateComponent,
  ExamList as ExamListComponent,
  ExamInfoModal as ExamInfoModalComponent,
  SeriesList as SeriesListComponent,
  ExamResult as ExamResultComponent,
  SeriesInfoModal as SeriesInfoModalComponent,
};

// export {
//     Basic as BasicExamTemplateComponent,
//     ExamList as ExamListComponent,
// };
