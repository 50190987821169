export default {
  masterList: [],
  masterDataList: [],

  difficultyLevelList: [],
  domainList: [],
  questionStatusList: [],
  questionTypeList: [],
  examTypeList: [],
  markingLevelList: [],
  examStatusList: [],
  userTypeList: [],
  modulesList: [],
  tagsList: [],
};
