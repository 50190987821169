import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const persistSlice = createSlice({
  name: 'persist',
  initialState,
  reducers: {
    updateUser(state, { payload }) {
      return { ...state, ...payload };
    },
  },
});

export const { updateUser } = persistSlice.actions;

export default persistSlice.reducer;

export const getUserClaims = () => async (dispatch, getState) => {
  try {
    // eslint-disable-next-line max-len
    // get user details
    const user = { name: 'anubhav' };
    dispatch(updateUser(user));
  } catch (err) {}
};
