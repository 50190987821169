import { createSlice } from '@reduxjs/toolkit';
import initialState from './initialState';

const commonSlice = createSlice({
  name: 'app',
  initialState,
  reducers: {
    setIsToastShown(state, { payload }) {
      state.isToastShown = payload;
    },
    setToastMessage(state, { payload }) {
      state.toastMessage = payload;
    },
  },
});

export const { setIsToastShown, setToastMessage } = commonSlice.actions;

export default commonSlice.reducer;

export const setToast =
  ({ isShown, message }) =>
  async (dispatch) => {
    if (isShown !== undefined) {
      dispatch(setIsToastShown(isShown));
    }
    if (message !== undefined) {
      dispatch(setToastMessage(message));
    }
  };
